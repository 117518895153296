import styled from "styled-components";

export const MainButton = styled.a`
    border-radius: 0.2rem;
    padding: 0.5rem;
    background: #6ea5ff;
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    text-align: center;
`;

export const OutlinedButton = styled.a`
    border-radius: 0.2rem;
    padding: 0.5rem;
    border: 1px solid #666666;
    color: #666666;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    text-align: center;
    `;

export const StatusHolder = styled.div`
    width: 100%;
    border-radius: 1.5rem;
    padding: 0.25rem 0.5rem;
    margin: 1rem 0;
`;

export const StatusLabel = styled.p`
    text-align: center;
`;
