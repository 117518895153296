import UserInfoActions from "../../../components/UserInfoCard/UserInfoActions";
import UserAvatar from "../../../components/UserInfoCard/UserAvatar";
import WrapperCard from "../../../components/WrapperCard/";
import GridItem from "./GridItem";

import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "@material-ui/core";
import { FaChevronDown } from "react-icons/fa";
import {
  MainButton,
  OutlinedButton,
} from "../../../components/UserInfoCard/style";
import formatValue from "../../../utils/formatValue";

const EMAIL_ADDRESS = "contato@7cantos.com";
const EMAIL_SUBJECT = "Tenho uma reclamação da 7cantos.";

export default function UserInfoCard({
  name,
  additionalData,
  smDown,
  ...props
}: any) {
  const {
    available_properties,
    rent_received,
    rent_received_by_date,
    rented_properties,
    schedules,
    time_to_rent,
  } = additionalData;

  const ownerFirstName = name?.split(" ")[0];
  const formattedTotal = formatValue(rent_received);
  const formattedReceivedRents = String(rent_received_by_date.length).padStart(
    2,
    "0"
  );

  const formattedAvailableProperties = `${String(available_properties).padStart(
    2,
    "0"
  )}`;
  const formattedRentedProperties = `${String(rented_properties).padStart(
    2,
    "0"
  )}`;

  const gridItems = [
    { label: "Aluguel líquido recebido", value: formattedTotal, xs: 12 },
    {
      label: "Prazo médio de locação",
      value: time_to_rent ? `${time_to_rent} dias` : "Não há dados",
      xs: 12,
    },
    {
      label: "Imóveis alugados pela 7cantos",
      value: formattedRentedProperties,
      xs: 6,
    },
    {
      label: "Imóveis anunciados",
      value: formattedAvailableProperties,
      xs: 6,
    },
    { label: "Aluguéis recebidos", value: formattedReceivedRents, xs: 6 },
    { label: "Visitas agendadas", value: schedules, xs: 6 },
  ];

  const children = (
    <>
      <Divider style={{ width: "100%", margin: "1rem 0" }} />
      {additionalData && <Grid container style={{ rowGap: "0.5rem" }}>
        {gridItems.map((item, index) => (
          <GridItem key={index} {...item} />
        ))}
      </Grid>}
      <Divider style={{ width: "100%", margin: "1rem 0" }} />
      <UserInfoActions>
        <MainButton
          href="https://api.whatsapp.com/send?text=7cantos"
          color="primary"
          style={{ marginTop: "1rem" }}
        >
          Suporte 7Cantos
        </MainButton>
        <OutlinedButton
          href={`mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
            EMAIL_SUBJECT
          )}`}
          color="dark"
          style={{ marginTop: "1rem" }}
        >
          Reclamações
        </OutlinedButton>
      </UserInfoActions>
    </>
  );

  return (
    <WrapperCard {...props}>
      {smDown && (
        <Accordion style={{ boxShadow: "none" }}>
          <AccordionSummary expandIcon={<FaChevronDown fontSize="14px" />}>
            <h5 style={{ fontSize: "1.2rem" }}>
              Bem-vindo(a), {ownerFirstName}!
            </h5>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            {children}
          </AccordionDetails>
        </Accordion>
      )}
      {!smDown && (
        <>
          <UserAvatar name={ownerFirstName} isTenant={false} />
          {children}
        </>
      )}
    </WrapperCard>
  );
}
