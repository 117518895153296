import React from 'react';

import { Link } from 'react-router-dom';

import { Container, Footer, FooterContent, ContactContainer, SocialMedias, SimiliarContents } from './styles';

import logoImg from './../../assets/components/Footer/7cantos.png';

import facebook from './../../assets/components/Footer/facebook.svg';
import instagram from './../../assets/components/Footer/instagram.svg';
import youtube from './../../assets/components/Footer/youtube.svg';
import whatsapp from './../../assets/components/Footer/whatsapp.svg';

export default function FooterComponent(){
	return (
		<Container>
			<Footer>
				<FooterContent>
					<ContactContainer>
						<img src={logoImg} alt="logo" />
						<p>contato@7cantos.com</p>
						<p>4003-7654</p>

						<SocialMedias>
							<a href="https://www.facebook.com/7cantoss/"><img src={facebook} alt="facebook"/></a>
				            <a href="https://www.instagram.com/7cantos/"><img src={instagram} alt="instagram"/></a>
				            <a href="https://www.youtube.com/channel/UCYMtGV7GKhkbRJmjSDjEbjQ"><img src={youtube} alt="youtube"/></a>
				            <a href="https://api.whatsapp.com/send/?phone=%2B5585994002470&text&type=phone_number&app_absent=0"><img src={whatsapp} alt="whatsapp"/></a>
						</SocialMedias>
					</ContactContainer>

					<SimiliarContents>
						<b>IMÓVEIS</b>

						<a href="https://mkt.7cantos.com/proprietarios" target="_blank">Anunciar Imóvel</a>
						<Link to="/indicar-imovel">Indique & Ganhe</Link>
					</SimiliarContents>

					<SimiliarContents>
						<b>7CANTOS</b>

						<Link to="/about-us">Sobre nós</Link>
						<a href="https://sites.google.com/7cantos.com/carreiras">Carreiras</a>
						<a href="https://clube7cantos.com.br/">Clube 7Cantos</a>
						<Link to='/use-terms'>Termos de Uso</Link>
						<Link to='/privacy-politics'>Política de Privacidade</Link>
					</SimiliarContents>

					<SimiliarContents>
						<b>CADASTRO</b>

						<Link to="/register">Criar conta</Link>
						<Link to={{
								pathname: "/login",
								state: {fromSite: true}
							}}>Fazer login</Link>
					</SimiliarContents>
				</FooterContent>
				
				<p>© Sete Cantos Serviços Imobiliários LTDA. Todos os direitos reservados. CRECI 1284-J.</p>
			</Footer>
		</Container>
	);
}