import React, { useState, useEffect } from "react";

//Components
import UserInformation from "./UserInfoCard";
// import NewOffers from "./NewOffers";
import RentabilityChart from "./RentabilityChart";
import Header from "../../components/Header";

import { useHistory } from "react-router-dom";
import DashboardSkeleton from "./Skeletons/DashboardSkeleton";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import Footer from "../../components/Footer";
import Payments from "./Payments";
import NewOffers from "./NewOffers";
import UserInfoNoData from "./UserInfoNoData";
import emptyDataImg from "../../assets/pages/OwnerDashboard/mensagem-prancheta 1.png";

interface IPayment {
  due_date: string;
  tenant: string;
  address: string;
  complement: string;
  value: string;
  status: string;
  payment_id: string;
  kenlo_property_id: string;
}

interface IOffer {
  date: string;
  tenant: string;
  address: string;
  status: string;
  tenant_id: number;
  property_id: number;
}

interface IRentByDate {
  date: string;
  final_value: number;
}

interface IAdditionalData {
  rented_properties: number;
  available_properties: number;
  schedules: number;
  time_to_rent: number;
  rent_received: number;
  rent_received_by_date: IRentByDate[];
}

interface IOwnerData {
  name: string;
  payments: IPayment[];
  offers: IOffer[];
  additional_data: IAdditionalData;
}

export default function OwnerDashboard() {
  const { access_token, user } = useAuth();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [ownerInfo, setOwnerInfo] = useState({} as IOwnerData);

  const ownerDetails = {
    name: ownerInfo.name,
    additionalData: ownerInfo.additional_data,
  };

  const chartData = ownerInfo.additional_data
    ? ownerInfo.additional_data.rent_received_by_date
    : [];

  useEffect(() => {
    const loadDashboardInfo = async () => {
      try {
        setLoading(true);

        if (!user) {
          history.push("/login");
          return;
        }
        if (user.type === "inquilino") {
          history.goBack();
          return;
        }
        api.defaults.headers.authorization = `Bearer ${access_token}`;
        const response = await api.get("/api/client/owner/dashboard");
        setOwnerInfo(response.data.data);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    loadDashboardInfo();
  }, [history, access_token, user]);

  return (
    <div style={{ margin: 0, padding: 0, position: "relative" }}>
      <Header hasBorder={!smDown} hasSearchBar hasOptionToSearch />
      <main
        style={{
          minHeight: "60vh",
          maxWidth: "1536px",
          padding: smDown ? '2rem 2rem 1rem' : "2rem 2rem 4rem",
        }}
      >
        {loading && <DashboardSkeleton smDown={smDown} />}
        {!loading && Object.keys(ownerInfo).length !== 0 && (
          <div
            style={{
              marginTop: "2rem",
              display: "flex",
              gap: "2rem",
              flexDirection: smDown ? "column" : "row",
            }}
          >
            <UserInformation
              smDown={smDown}
              width={smDown ? "100%" : "25%"}
              boxSizing="border-box"
              {...ownerDetails}
            />
            <div
              style={{
                width: smDown ? "100%" : "75%",
                boxSizing: "border-box",
                rowGap: "2rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Payments paymentsList={ownerInfo.payments} smDown={smDown} />
              <div
                style={{
                  gap: "2rem",
                  display: "flex",
                  flexDirection: smDown ? "column" : "row",
                }}
              >
                <RentabilityChart
                  boxSizing="border-box"
                  smDown={smDown}
                  width={smDown ? "100%" : "60%"}
                  chartData={chartData}
                />
                <NewOffers
                  smDown={smDown}
                  offersList={ownerInfo.offers}
                  boxSizing="border-box"
                  width={smDown ? "100%" : "40%"}
                />
              </div>
            </div>
          </div>
        )}
        {!loading && Object.keys(ownerInfo).length === 0 && (
          <div
            style={{
              display: "flex",
              gap: "2rem",
              flexDirection: smDown ? "column" : "row",
            }}
          >
            <UserInfoNoData
              smDown={smDown}
              width={smDown ? "100%" : "30%"}
              boxSizing="border-box"
            />
            <div
              style={{
                width: smDown ? "100%" : "70%",
                display: "flex",
                flexDirection: smDown? 'column': 'row',
                alignItems: smDown ? 'flex-start': 'center',
                boxSizing: "border-box",
              }}
            >
              {smDown && <h2 style={{marginBottom: '0.8rem'}}>Seus Rendimentos</h2>}
              <img width={smDown ?'100%': "50%" } src={emptyDataImg} style={{margin: '16px 0'}}/>
              <div className="text-container">
                {!smDown && <h2 style={{marginBottom: '0.8rem'}}>Seus Rendimentos</h2>}
                <p>
                  Por enquanto, seu histórico ainda está vazio. À medida que
                  novos boletos forem sendo pagos, seus rendimentos aparecerão
                  aqui.
                </p>
              </div>
            </div>
          </div>
        )}
      </main>
      {!loading && Object.keys(ownerInfo).length !== 0 && (
        <div
          style={{
            width: "100%",
            background: "#cfddf2",
            height: "110vh",
            overflow: "hidden",
            position: "absolute",
            top: "30vh",
            zIndex: -1,
          }}
        />
      )}
      <Footer />
    </div>
  );
}
