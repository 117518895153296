import React from "react";
import { Container, Content, PaymentsAndMenuContainer } from "./style";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import api from "./../../services/api";
import Loading from "../../components/Loading";
import UserInfoCard from "../../components/UserInfoCard";
import Payments from "./Payments";
import Menu from "./Menu";

import { useMediaQuery, useTheme } from "@material-ui/core";

interface IBills {
  address: string;
  due_date: string;
  link: string;
  status: string;
  value: string;
}

interface ITenantBills {
  boletos: IBills[];
  contract_termination: number;
  name: string;
  rent_process: number;
}

const deriveTenantName = (tenantBills: ITenantBills) => {
  if (tenantBills.name) return tenantBills.name;
};
const deriveTenantStatus = (tenantBills: ITenantBills) => {
  if (tenantBills.boletos) return tenantBills.boletos[0].status;
};

const deriveAddress = (tenantBills: ITenantBills) => {
  if (tenantBills.boletos)
    return tenantBills.boletos[tenantBills.boletos.length - 1].address;
};

const deriveTerminationId = (tenantBills: ITenantBills) => {
  if (tenantBills.contract_termination) return tenantBills.contract_termination;
};
const deriveProcessId = (tenantBills: ITenantBills) => {
  if (tenantBills.rent_process) return tenantBills.rent_process;
};

const deriveBillsList = (tenantBills: ITenantBills) => {
  if (tenantBills.boletos) {
    var reverseBills = [];
    for (var i = tenantBills.boletos.length - 1; i >= 0; i--) {
      reverseBills.push(tenantBills.boletos[i]);
    }
    return reverseBills;
  }
};

export default function TenantDashboard() {
  const { access_token, user } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [tenantBills, setTenantBills] = React.useState({} as ITenantBills);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  const userInfo = {
    name: deriveTenantName(tenantBills) ?? user?.first_name,
    status: deriveTenantStatus(tenantBills),
    address: deriveAddress(tenantBills),
  };

  const billsList = deriveBillsList(tenantBills);
  const terminationId = deriveTerminationId(tenantBills);
  const processId = deriveProcessId(tenantBills);

  React.useEffect(() => {
    if (!user) {
      history.push("/login");
    } else if (user?.type !== "inquilino") {
      // history.goBack();
      history.push("/owner-dashboard");
    }
    const loadDashboardInfo = async () => {
      try {
        setLoading(true);

        api.defaults.headers.authorization = `Bearer ${access_token}`;

        const response = await api.get("/api/client/tenant/dashboard");

        setTenantBills(response.data.data);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    };
    loadDashboardInfo();
  }, [history, access_token, user]);
  return (
    <Container>
      <Header hasBorder={!smDown} hasSearchBar hasOptionToSearch />
      {loading && <Loading />}
      {!loading && (
        <Content>
          <UserInfoCard
            width={smDown ? "100%" : "25%"}
            smDown={smDown}
            boxSizing="border-box"
            {...userInfo}
          />
          <PaymentsAndMenuContainer>
            <Payments bills={billsList} />
            <Menu
              terminationId={terminationId}
              processId={processId}
              smDown={smDown}
            />
          </PaymentsAndMenuContainer>
        </Content>
      )}
      <Footer />
    </Container>
  );
}
