import React, { ChangeEvent, useState } from "react";
import { TABLE_TITLES } from "./constants";
import loadingGif from "../../../../assets/components/loading.gif";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FaFileDownload } from "react-icons/fa";
import { Pagination } from "@material-ui/lab";
import api from "../../../../services/api";
import formatValue from "../../../../utils/formatValue";

interface IPayment {
  due_date: string;
  tenant: string;
  address: string;
  complement: string;
  value: string;
  status: string;
  payment_id: string;
  kenlo_property_id: string;
}

interface IBills {
  bills: IPayment[];
}

const isReceived = (status: string) => status === "RECEIVED";

const StatusCell = ({ status }: any) => {
  let statusText;
  let textColor;
  switch (status) {
    case "RECEIVED":
      statusText = "Recebido";
      textColor = "#0ca11a";
      break;
    case "SENT":
      statusText = "A receber";
      textColor = "#8b8a8e";
      break;
    default:
      statusText = "Atrasado";
      textColor = "#b91c1b";
      break;
  }
  return (
    <TableCell style={{ color: textColor, whiteSpace: "nowrap" }}>
      {statusText}
    </TableCell>
  );
};

export default function OptionsTable({ bills }: IBills) {
  const [isDownloadingPayment, setIsDownloadingPayment] = useState({
    isDowloading: false,
    paymentId: null,
  });
  const PAGE_SIZE = 7;

  const billsListLength = bills.length;
  const paginationLength = Math.floor(billsListLength / 7) + 1;
  const [range, setRange] = useState({
    startIndex: 0,
    endIndex: PAGE_SIZE - 1,
  });

  function pageChangeHandler(event: ChangeEvent<unknown>, page: number) {
    setRange((prevValue) => ({
      ...prevValue,
      startIndex: (page - 1) * PAGE_SIZE,
      endIndex:
        page * PAGE_SIZE - 1 > billsListLength
          ? billsListLength
          : page * PAGE_SIZE - 1,
    }));
  }

  const displayableBills = bills.slice(range.startIndex, range.endIndex + 1);

  async function handleDownloadPayment({ paymentId, date }: any) {
    setIsDownloadingPayment({ isDowloading: true, paymentId });
    try {
      const response = await api.get(
        `/api/client/owner/dashboard/payment/${paymentId}`,
        {
          responseType: "arraybuffer",
        }
      );
      // SnackbarService.showSuccess({ message: "Boleto baixado com sucesso!" });
      const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", `${date} - ${paymentId} - 7cantos.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);

      // SnackbarService.showError({
      //   message: "Houve um erro ao baixar o boleto. Tente novamente mais tarde",
      // });
    }
    setIsDownloadingPayment({ isDowloading: false, paymentId: null });
  }

  return (
    <TableContainer
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "2rem",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_TITLES.map((title) => (
              <TableCell align="left" key={title} style={{ color: "#8b8a8e" }}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayableBills?.map((bill: IPayment) => {
            const value = Number.parseFloat(bill.value);
            const received = isReceived(bill.status);
            const address = bill.address + ", " + bill.complement;
            const paymentData = {
              date: bill.due_date,
              paymentId: bill.payment_id,
            };
            return (
              <TableRow key={bill.tenant}>
                <TableCell>{bill.due_date}</TableCell>
                <TableCell>{bill.tenant}</TableCell>
                <TableCell>{address}</TableCell>
                <TableCell>{formatValue(value)}</TableCell>
                <StatusCell status={bill.status} />
                <TableCell style={{ textAlign: "center" }}>
                  {isDownloadingPayment.isDowloading &&
                  bill.payment_id === isDownloadingPayment.paymentId ? (
                    <img src={loadingGif} alt="Baixando..." width="25px" />
                  ) : (
                    <FaFileDownload
                      onClick={
                        received
                          ? () => handleDownloadPayment(paymentData)
                          : () => {}
                      }
                      style={{
                        color: received ? "rgb(110, 165, 255)" : "#8b8a8e",
                        cursor: received ? "pointer" : "not-allowed",
                      }}
                    />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination count={paginationLength} onChange={pageChangeHandler} />
    </TableContainer>
  );
}
