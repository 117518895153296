import CardHeader from "../../../components/CardHeader";
import OptionsTable from "./OptionsTable/OptionsTable";
import WrapperCard from "../../../components/WrapperCard/";
// import MonthPicker from "./MonthPicker/MonthPicker";
import React, { useState } from "react";
import TicketsListSkeleton from "../Skeletons/TicketsListSkeleton";
import { FaRegListAlt } from "react-icons/fa";
import OptionsAccordion from "./OptionsAccordion";
import MonthPicker from "./MonthPicker";
import api from "../../../services/api";

interface IPayment {
  due_date: string;
  tenant: string;
  address: string;
  complement: string;
  value: string;
  status: string;
  payment_id: string;
  kenlo_property_id: string;
}


export default function Payments({ paymentsList, smDown, ...props }: any) {
  const [ticketsList, setTicketsList] = useState<IPayment[]>(paymentsList);
  const [updatingTicketsList, setUpdatingTicketsList] = useState(false);

  async function handleSelectDate(date: string) {
    const [year, month] = date.split('-');
    const lastDay = new Date(2008, parseInt(month), 0).getDate();
    const initial_date = `${month}/01/${year}`;
    const final_date = `${month}/${lastDay}/${year}`;
    const period = { initial_date, final_date };

    setUpdatingTicketsList(true);
    try {
      const response = await api.get("/api/client/owner/dashboard", {
        params: period,
      });
      setTicketsList(response.data.data.payments);
    } catch (response) {
      // SnackbarService.showError({
      //   message:
      //     "Ocorreu um erro buscando os boletos. Tente novamente mais tarde",
      // });
    }
    setUpdatingTicketsList(false);
  }

  let content = <TicketsListSkeleton />;
  if (!updatingTicketsList)
    content = smDown ? (
      <OptionsAccordion bills={ticketsList} />
    ) : (
      <OptionsTable bills={ticketsList} />
    );

  return (
    <WrapperCard padding={smDown ? "0.8rem" : "2rem"} {...props}>
      <CardHeader
        Icon={
          <FaRegListAlt
            style={{
              fontSize: "30px",
              margin: "0 0.25rem 0 0",
              color: "#6ea7ff",
            }}
          />
        }
        title="Histórico de Pagamentos"
        flexDirection={smDown ? "column" : "row"}
        alignItems={smDown ? "flex-start" : "center"}
        rowGap="0.5rem"
      >
        <MonthPicker
          onDateSelect={handleSelectDate}
        />
      </CardHeader>
      {content}
    </WrapperCard>
  );
}
