// import AddressMap from "./AddressMap";
// import UserAvatar from "../../../components/UserInfo/UserAvatar";
import UserInfoActions from "./UserInfoActions";
import WrapperCard from "../WrapperCard";
// import { ExpandMoreOutlined } from "@mui/icons-material";
// import MapSection from "./MapSection/MapSection";
import React from "react";
import { MainButton, OutlinedButton, StatusHolder, StatusLabel } from "./style";
import UserAvatar from "./UserAvatar";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from "@material-ui/core";
import { MdExpandMore } from "react-icons/md";
import MapSection from "./MapSection";

const RentStatus = ({ status }: any) => {
  let color;
  let text;

  switch (status) {
    case "RECEIVED":
      color = "#0ca11a";
      text = "Tudo Certo";
      break;
    case "SENT":
      color = "#8b8a8e";
      text = "Enviado";
      break;
    default:
      color = "#b91c1b";
      text = "Atrasado";
      break;
  }
  return (
    <StatusHolder style={{ border: `2px solid ${color}` }}>
      <StatusLabel>
        Status do Aluguel: <b style={{ color }}>{text}!</b>
      </StatusLabel>
    </StatusHolder>
  );
};
const EMAIL_ADDRESS = "contato@7cantos.com";
const EMAIL_SUBJECT = "Tenho uma reclamação da 7cantos.";

export default function UserInfoCard({
  name,
  status,
  address,
  smDown,
  ...props
}: any) {
  const tenantFirstName = name?.split(" ")[0];

  const children = (
    <>
      {status && <RentStatus status={status} />}
      {!!address && process.env.REACT_APP_GOOGLE_MAPS_KEY && (
        <MapSection address={address} />
      )}
      <Divider style={{ width: "100%", margin: "1rem 0" }} />
      <UserInfoActions>
        <MainButton
          href="https://api.whatsapp.com/send?text=7cantos"
          color="primary"
          style={{ marginTop: "1rem" }}
        >
          Suporte 7Cantos
        </MainButton>
        <OutlinedButton
          href={`mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
            EMAIL_SUBJECT
          )}`}
          color="dark"
          style={{ marginTop: "1rem" }}
        >
          Reclamações
        </OutlinedButton>
      </UserInfoActions>
    </>
  );
  return (
    <WrapperCard {...props}>
      {smDown && (
        <Accordion style={{ boxShadow: "none" }}>
          <AccordionSummary expandIcon={<MdExpandMore />}>
            <h5 style={{ fontSize: "1.2rem" }}>
              Bem-vindo(a), {tenantFirstName}!
            </h5>
          </AccordionSummary>
          <AccordionDetails
            style={{ display: "flex", flexDirection: "column" }}
          >
            {children}
          </AccordionDetails>
        </Accordion>
      )}
      {!smDown && (
        <>
          <UserAvatar name={tenantFirstName} isTenant />
          {children}
        </>
      )}
    </WrapperCard>
  );
}
